/* Layouts
----------------------------------*/
// .phx-contentPanel {

//     /* 	padding: 1em; */
//     * {
//         /* 	margin-bottom: 0.5em; */
//         /* vertical-align: middle; */
//     }
// }

.phx-listeDetailPanel .phx-splitB .phx-contentPanel {
    padding: 5px 5px 5px 10px;
}

.phx-listePanel {

    .phx-panelA1,
    .phx-panelA2 {
        padding: 5px 5px 5px 10px;
    }
}

/* Avoid buttons too large in IE */
.phx-contentPanel button span {
    margin-bottom: 0;
}

.phx-simplePanel {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;

    .phx-splitA {
        width: 100%;
        /* 	overflow: auto; */
    }
}

.phx-splitPanel {
    width: 100%;

    /* 	overflow: hidden; */
    /* 	display: table; */
    .phx-splitAcell {
        /* 	overflow-x: hidden; */
        /* 	overflow-y: auto; */
        margin-right: 5px;
    }

    .phx-splitA {
        width: 250px;
        /* 	overflow-x: hidden; */
        width: 5px;
        background: transparent;
        background-repeat: repeat-y;
        background-position: 100%;
        background-position: right;
        background-size: 5px;
        float: left;
    }

    .phx-splitBcell {
        width: 100%;
        vertical-align: top;
        /*  	overflow: hidden;  */
        /* 	display: table-cell; */
    }
}

/* fieldset .phx-splitPanel { */
/* 	margin: 0px; */
/* } */
.phx-splitBcell {
    .phx-splitB {
        vertical-align: top;
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .phx-panelB1,
    .phx-panelB2,
    .phx-panelB3 {
        overflow-x: hidden;
    }

    .phx-panelB4 {
        /* 	overflow-x: hidden; */
        overflow-x: auto;
        overflow-y: auto;
        padding-left: 8% !important;
        padding-bottom: 0 !important;
        &.phx-no-indented{
            padding-left: 24px !important;
        }
    }
    



}

#fichecollab .phx-panelB4,
#suivicollab .phx-panelB4 {
    padding: 24px !important;
}

.phx-splitA {
    // .phx-panelA2 {
    //     /* Customer 157965. This style causes error in IE.*/
    //     /*overflow: auto;*/
    // }

    .phx-panelA3 {
        overflow: auto;
    }
}

.ui-resizable-goto-left,
.ui-resizable-goto-right {
    position: absolute;
    font-size: 2em;
    cursor: pointer;
    padding-left: 2px;
    padding-right: 2px;
}

.phx-hideSplitter {
    background: none !important;

    >.phx-splitAcell {
        border-right: 0;
    }
}

/* Forms
----------------------------------*/
fieldset:not(.cw-fieldset) {
    /* border: 1px solid #2694E8; */
    padding: 0.5em;

    /*overflow-x: hidden; */
    /*border-radius: 6px;*/
    &.cleaned {
        border: none !important;
        padding: 0 !important;
        overflow-x: visible !important;
        border-radius: 0 !important;
        display: inline-block !important;

        label {
            margin-right: 0.1em;
            width: 1em;
            float: none;
            text-align: right;
            padding-right: 0;
            margin-top: 0;
            margin-bottom: 0;
            height: 11px;
            display: inline-block;
        }
    }

    * {
        margin-bottom: 4px;
    }

    select option {
        margin-bottom: 0;
    }

    fieldset.phx-ui-radio legend,
    label {
        display: unset;
        margin-right: 0.25em;
        min-width: 10em;
        float: left;
        text-align: right;
        padding-right: 1em;
        margin-top: 2px;
        margin-bottom: 2px;
        height: 13px;
        box-sizing: content-box;
    }

    label.required {
        background-repeat: no-repeat;
        background-position: right top;
    }
}

td label.required {
    background-repeat: no-repeat;
    background-position: right top;
}

fieldset {
    label.phx-inline-label {
        /* 	margin-right: 0px; */
        /* 	padding-right: 0px; */
        /* 	width: 0px; */
        padding-right: 0;
        margin-left: 0.5em;
        float: none;
    }

    input {
        width: auto;
        font-family: Tahoma, Arial, Helvetica, Verdana, sans-serif;
        padding: 1px 1px 2px;
    }
}

select {
    width: auto;
    font-family: Tahoma, Arial, Helvetica, Verdana, sans-serif;
    padding: 1px 1px 2px;
}

fieldset:not(.cw-fieldset) {
    div.phx-formTable {
        white-space: nowrap;

        /* 	overflow-x: hidden; */
        /*border-radius: 6px;*/
        div {
            &.phx-column1 {
                display: inline;
                float: left;
            }

            &.phx-column2 {
                display: inline;
            }

            &.phx-column-clear {
                clear: both;
            }
        }
    }

    input[type=checkbox] {
        height: 13px;
        margin-top: 2px;
        margin-bottom: 2px;
    }
}

/*
Added to solve chrome problem with float:left in checkbox that doesn't put next label over the previous label.
*/
span[readonly] {
    border-color: transparent;
    background: none;
    font-weight: bold;
    display: inline-block;
    margin-top: 2px;
    margin-bottom: 2px;
    min-height: 16px;
}

fieldset:not(.cw-fieldset) {

    input[readonly],
    select[readonly],
    textarea[readonly] {
        border-color: transparent;
        background: none;
        font-weight: bold;
        text-align: left;
        font-family: Arial, Helvetica, sans-serif;
        letter-spacing: normal;
        font-size: 1.00em;
        /* 	vertical-align: middle; */
        padding-left: 0;
    }
}

table.ui-grid-table td {

    input[readonly],
    select[readonly],
    textarea[readonly] {
        border-color: transparent;
        background: none;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
        letter-spacing: normal;
        font-size: 1.00em;
        padding-left: 0;
    }
}

input,
select,
textarea {
    font-weight: bold;
    letter-spacing: 0.5pt;
}

fieldset:not(.cw-fieldset) legend,
label {
    font-size: 0.9em;
    /* 7.5pt -> 10px*/
}

.phx-formErrors.ui-state-error {
    background: inherit;
    border: none;
}

fieldset:not(.cw-fieldset) {

    div.ui-state-error:not(.phx-combobox-content),
    label.ui-state-error {
        background: inherit;
        border: none;
    }
}

td label.ui-state-error {
    background: inherit;
    border: none;
}

.phx-formErrors {
    display: none;

    &.ui-state-error {
        font-weight: bold;
    }
}

input {

    &[type=checkbox].ui-state-error,
    &[type=radio].ui-state-error {
        outline: solid 1px red;
    }
}

fieldset:not(.cw-fieldset) div.phx-field-error {
    margin-left: 10.5em;
    padding-left: 12px;
    background-repeat: no-repeat;
    background-position: left center;
    white-space: normal;
    font-size: 0.9em;
    /* 7.5pt -> 10px*/
}

#nouvelleFicheCollaborateur fieldset div.phx-field-error {
    //padding-left: 52px;
}

#nouvelleFicheCollaborateur .phx-combobox .phx-field-error {
    //padding-left: 0;
}

td div.phx-field-error {
    margin-left: 0;
    padding-left: 12px;
    background-repeat: no-repeat;
    background-position: left center;
    border: none;
    background-color: transparent;
    white-space: normal;
}

// fieldset label.ui-state-error,
// td label.ui-state-error {
//     /* 	background-repeat: no-repeat; */
//     /* 	background-position: right top; */
// }

/* Form error message */
/* Grid error message */
/* Button Bar
----------------------------------*/
.phx-buttonBar {
    padding: 10px 4px;
    white-space: nowrap;

    button {
        height: 30px;
    }

    .ui-button .ui-button-text {
        font-weight: bold;
        font-size: 0.8em;
        /*7pt -> 9px */
    }
}

/*this is needed to fix a bug in jQuery UI button()*/
.ui-button-text {
    font-size: 0.8em;
    /*7pt -> 9px */
}

.phx-buttonBar .phx-buttonBar-menu-content {
    li {
        padding: 4px;
        font-size: 1em;
        font-weight: bold;
        line-height: normal;
    }

    border-radius: 6px;
}

/* Accordion
----------------------------------*/
.ui-accordion {
    .ui-accordion-content {
        padding: 0.5em 1.1em;
    }

    ul li a {
        text-decoration: none;
        padding-left: 2px;
        padding-right: 2px;
        background: none;
        border: none;
    }
}

/** Header
------------------------------------ */
.phx-header {
    a {
        text-decoration: none;
    }

    .phx-zones-menu> {
        li {
            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }

            display: inline-block;
        }

        a {
            display: inline-block;
        }


    }
}

/* Zone selector links */
.phx-zone-selector {
    li {
        font-weight: normal;
        margin: 0 5px;
    }

    a.ui-state-active,
    li.ui-state-active {
        font-weight: bold;
        background: none;
        border: none;
        margin: 0 5px;
    }
}

/* Account Header */
#phx-account {
    background: none;
    border: none;
}

/** Module header
------------------------------------ */
/** Navigation */
.phx-splitB div {

    &.phx-panelB1,
    &.phx-panelB3 {
        border-top: none;
        border-left: none;
        border-right: none;
        background: none;
    }
}

/* TODO: change the name */
.menuText {
    font-weight: bold;
    margin-top: 5px;
}

/** Details header */
.phx-details-header-text {
    font-size: 1.3em;
    /*10.5pt -> 14px */
    font-weight: bold;
}

/* Override button height
----------------------------------*/
button.ui-button-icon-only {
    height: 15px;
}

/* Override tooltip theme
----------------------------------*/
.ui-tooltip {
    padding: 3px 6px;
    max-width: none;
    border: true;
    -webkit-box-shadow: none;
    box-shadow: none;
}

/** Modal Div
------------------------------------ */
.phx-modal {
    position: absolute;
    width: 100%;
    height: 100%;
    filter: alpha(opacity=60);
    opacity: 0.6;
    -moz-opacity: 0.6;
    z-index: 100;
}

/** autocomplete
-----------------------------------------------*/
/* height of the dropdown liste of the autocomplete component*/
.ui-autocomplete {
    max-height: 400px;
    overflow-y: visible;
    /* prevent horizontal scrollbar */
    overflow-x: hidden;

    .ui-menu-item a {
        min-height: 1em;
    }

    &.phx-chemin-autocomplete-menu .ui-menu-item a,
    &.phx-combobox .ui-menu-item a {
        white-space: nowrap;
    }

    &.phx-selecteur-activite-autocomplete-menu .ui-menu-item a {
        white-space: normal;
    }

    &.c-panneauMenu .ui-menu-item span {
        white-space: pre-wrap;
        line-height: normal;
    }
}

/* Min height for empty row in autocomplete dropdown*/
/* size of the button*/
.phx-autocomplete-button {
    height: 21px;
    margin: 0;
    bottom: 1px;
}

/* Tabs */
ul.ui-tabs-nav li span {
    line-height: 22px;
    cursor: pointer;
}

ul.ui-tabs-nav li.ui-tabs-active.ui-state-active.ui-state-focus {
    outline: none;
}

/** configuration of the schedule component  **/
/* -------------------------------------------*/
.phx-schedule-color-item {
    display: inline-block;
}

.phx-schedule-text-item {
    font-size: 0.7em;
    /*6pt -> 8px */
    display: inline-block;
    position: relative;
    left: -10px;
}

/** selecteur referentiel
-----------------------------------------------------*/
.phx-selecteur-referentiel span * {
    overflow: hidden;
}

.phx-referentiel-button {
    margin: 0;
    bottom: 1px;
    float: right;
    height: 13px;
}

.phx-referentiel-wrap {
    margin: 0 !important;
    display: inline-block;
    overflow: hidden;
    border: none;

    .phx-referentiel-input {
        margin-bottom: 0 !important;
        float: left;
    }
}

.phx-referentiel-button .ui-button-text {
    padding: 0 5px;
}

.phx-referentiel-item {
    margin: 1px 0 0 1px;
    cursor: pointer;
    padding: 3px 1px;
    float: left;
}

.phx-referentiel-item-text {
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 0 0 2px;
}

.phx-referentiel-item-icon {
    margin: 0;
    padding: 0 2px 0 0;
}

.phx-referentiel-moreitems-info {
    font-size: 0.8em;
    font-style: italic;
}

.ui-autocomplete.phx-selecteur-referentiel-autocomplete-menu .ui-state-disabled {
    opacity: 1;
}

/** list builder
-----------------------------------------------------*/
.phx-list-builder span * {
    overflow: hidden;
}

.phx-list-builder-button {
    margin: 0;
    bottom: 1px;
    float: right;
    height: 13px;
}

.phx-list-builder-wrap {
    margin: 0 !important;
    display: inline-block;
    overflow: hidden;
    border: none;

    .phx-referentiel-input {
        margin-bottom: 0 !important;
        float: left;
    }
}

.phx-list-builder-button .ui-button-text {
    padding: 0 5px;
}

.phx-list-builder-item {
    margin: 1px 0 0 1px;
    cursor: pointer;
    padding: 3px 1px;
    float: left;
}

.phx-list-builder-item-text {
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 0 0 2px;
}

.phx-list-builder-item-icon {
    margin: 0;
    padding: 0 2px 0 0;
}

.phx-list-builder-moreitems-info {
    font-size: 0.8em;
    font-style: italic;
}

/** selecteur chemin
-----------------------------------------------------*/
.phx-selecteur-chemin span * {
    display: inline-block;
    overflow: hidden;
}

.phx-chemin-button {
    margin: 0;
    bottom: 1px;
    float: right;
    height: 13px;
}

.phx-chemin-wrap {
    margin: 0 !important;
    display: table-footer-group;
    overflow: hidden;
    border: none;

    .phx-chemin-input {
        margin-bottom: 0 !important;
        float: left;
    }
}

.phx-chemin-button .ui-chemin-text {
    padding: 0 5px;
}

.CompAffectations .phx-vuejourneeaffectation-affectation .phx-selecteur-chemin .phx-chemin-wrap .phx-chemin-input {
    margin-top: -2px;
}

.phx-chemindlg-selection {
    height: 22px;
}

.phx-chemin-item {
    margin: 1px 0 0 1px;
    cursor: pointer;
    padding: 3px 1px;
    float: left;
}

.phx-chemin-item-text {
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 0 0 2px;
    float: left;
    /*     max-width : 250px; */
}

.phx-chemin-item-icon {
    margin: 0;
    /* 	top : 4px; */
    /* 	position : relative; */
    padding: 0 2px 0 0;
    float: left;
}

.phx-chemin-moreitems-info {
    font-size: 0.8em;
    font-style: italic;
}

.ui-autocomplete.phx-selecteur-chemin-autocomplete-menu .ui-state-disabled {
    opacity: 1;
}

/**
 * Tooltips Styles
 */
.phx-tooltip-italic {
    font-style: italic;
}

/** Types
-----------------------------------------------------*/
//#phx-zone-ref,
//#phx-zone-adm {

.typeCustom,
.typeDecimal,
.typeDecimal_1,
.typeDecimal_2,
.typeDurationHC,
.typeDurationHM,
.typeDuration24HM,
.typeDuration24HC,
.typeDurationHMS,
.typeDurationMN,
.typeHourMinuteSecond,
.typeHourMinute,
.typeHourMinuteNightly,
.typeJour,
.typeJour_0,
.typeJour_1,
.typeJour_2,
.typeLong,
.typePercentage,
.typePercentage_0,
.typePercentage_1,
.typePercentage_2,
.typeShort {
    text-align: right;
}

//}

/** General menu
-------------------------------------------------*/
.phx-menu {
    display: inline-block;
    margin-right: 3px;
    font-weight: normal;
    padding-right: 2px;
    padding-left: 2px;

    >div {
        position: absolute;
        z-index: 1001;
        padding: 5px;
    }

    span {
        margin: 0;
        padding: 0;
    }
}

/** z-index greater than z-index for hidder*/
.phx-menu-icon {
    margin: 0;
    top: 2px;
    position: relative;
    padding: 0 2px 0 0;
}

.phx-menu:hover {
    /* 	color is defined in theme */
    cursor: pointer;
}

.phx-menu-link {
    padding-right: 2px;
    padding-left: 2px;

    a {
        display: list-item;
    }
}

/** Navigation Bar view
-------------------------------------------------*/
.phx-navigation-bar {
    .phx-navigation-buttons {
        float: right;
    }

    white-space: nowrap;
}

/***************************************************/
/*-- portlets */
/***************************************************/
.phx-portlet-container {
    width: 100%;
    //overflow-y: auto;
    padding: 18px;
    overflow-x: hidden;
    margin: 0px !important;
    overflow-y: overlay;
}

#homecoll .phx-portlet-container {
    padding: 0px 12px;
}

// div.phx-portlet {
//     /*float: left;*/
// }

.phx-portlet-div {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
}

// .phx-portlet {
//     /* margin: 0 1em 1em 0;
//      overflow: hidden;
//      min-width: calc(100%/4);*/
// }

.phx-portlet-header {
    padding: 0px 0px 12px 0px;

    .ui-icon {
        float: right;
    }
}

.phx-portlet-content {
    padding: 0.4em;
}

.phx-portlet {
    &[aria-expanded=true] {
        height: auto !important;
    }

    &[aria-expanded=false] .phx-portlet-content {
        display: none !important;
    }
}

.phx-portlet-1tier {
    width: calc(100%/3 - 20px);
}

.phx-portlet-2tier {
    width: calc(200%/3 - 20px);
}

.phx-portlet {
    &.ui-sortable-placeholder {
        visibility: visible !important;
        height: 50px !important;
    }

    .ui-resizable-s {
        bottom: 0 !important;
    }

    .ui-resizable-e {
        right: 0 !important;
    }
}

/* .phx-portlet .ui-sortable-placeholder * { visibility: hidden; } */
/********************************************/
/* USER MENU                                */
/********************************************/
#phx-user-menu {

    .ui-menu {
        right: 25px;
    }
}

/***************************************************/
/*-- tabs */
/***************************************************/
.ui-tabs .ui-tabs-panel {
    padding: 0;
}

/***************************************************/
/* HISTORIFICATION COMPONENT                       */
/***************************************************/
.phx-historification a.ui-state-active {
    font-weight: bold;
    background: none;
    border: none;
    text-decoration: none;
}

/******************************
*Drop down view container component
*/
.phx-block,
.phx-block-container {
    white-space: nowrap;
    padding: 2px;
    margin-bottom: 0;
}

.phx-block {
    .phx-block-title-icon {
        display: inline-table;
        margin-right: -16px;
        margin-bottom: -16px;
    }

    .phx-block-title-text {
        display: inline-table;
        margin-left: 2em;
        white-space: normal;
        word-wrap: break-word;
        position: relative;
    }
}

.phx-block-container .phx-block-container-title-text {
    display: inline-table;
    white-space: normal;
    word-wrap: break-word;
}

.phx-block .phx-block-content {
    margin-left: 2em;
    margin-top: 0.6em;
    white-space: normal;
    color: initial;
    word-wrap: break-word;
}

.phx-block-container .phx-block-container-content {
    white-space: normal;
    /*color: initial;*/
    word-wrap: break-word;
}

.btn_recap_activite_component,
.phx-block .btn_vuejour_component {
    float: right;
    margin-top: -2px;
}

.phx-vuejourneemessage-message {
    cursor: pointer;

    // &:hover {
    //     font-weight: normal !important;
    // }
}

.phx-vuejourneeregularisation-regularisation {
    cursor: pointer;
}

/*
.phx-vuejourneeregularisation-regularisation:hover {
	font-weight: bold !important;
} */
.phx-wkfhisto-table {

    /* 	border-style:solid; */
    td {
        white-space: nowrap;
    }
}

/* Anomalie bloquante */
.phx-anomalies-bloquante {
    color: red;
}

/* Anomalie persistente */
.phx-anomalies-persistente {
    color: orange;
}

/* Anomalie non bloquante */
.phx-anomalies-non-bloquante {
    color: green;
}

/*vue journee disponibilite*/
.phx-vuejourneedisponibilite-disponibilite:hover {
    cursor: pointer;
}

/***************************************************/
/* DISABLE SELECTION */
/***************************************************/
.disable-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -o-user-select: none;
    -khtml-user-select: none;
}

/***************************************************/
/* Cell Styles */
/***************************************************/
.ui-phx-info-cell-style div {
    &.phx-cell-render {
        /* 	width: 100%; */
        height: 100%;
        line-height: 20px;
        white-space: nowrap;
        overflow: inherit;
        position: relative;
    }

    div {
        &.phx-cell-render-left {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 50%;
        }

        &.phx-cell-render-right {
            position: absolute;
            top: 0;
            left: 50%;
            height: 100%;
            width: 50%;
        }

        &.phx-cell-render-center {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }

        &.phx-cell-render-text-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
        }

        * {
            font-size: 9pt;
            font-weight: 400;
        }

        &.cw-cell-render__deuxPlages {
            justify-content: center;
            display: flex;
            align-items: center;

            &.cwReduitCell {
                width: 32% !important;
            }
        }
    }
}

table.tableCounters td div.phx-cell-render-text-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.ui-phx-info-cell-style div div.phx-cell-render-text,
table.tableCounters td div.phx-cell-render-text {
    vertical-align: middle;
    text-align: center;
}

.ui-phx-info-cell-style div div.phx-cell-render-ind {
    position: absolute;
    top: 0;
    right: 0;
}

.ui-phx-info-cell-style div div.cw-cell-render-memo-mois,
.ui-phx-info-cell-style div div.cw-cell-render-memo-med {
    float: right;
    position: relative;
    top: -7px;
}

.ui-phx-info-cell-style div div.cw-cell-render-memo-hebdo {
    float: right;
    position: relative;
    top: -5px;
}

.ui-phx-info-cell-style div div.cw-cell-render-memo-hebdo-forfaitj {
    top: -4px;
    left: -2px
}

/** Radio Box
----------------------------------------------*/
fieldset.phx-ui-radio {
    display: inline-block;
    padding: 2px 0 0;

    .phx-ui-radio-options {
        display: inline-block;
        margin-top: 0.5em;
    }
}

fieldset fieldset.phx-ui-radio>legend {
    font-weight: normal;
    margin-top: 0.5em;
}

/** Multi Label component
----------------------------------------------*/
.phx-multiLabel {
    .phx-multiLabel-btn {
        float: left;
        margin-right: 0.5em;
        cursor: pointer;
    }

    .phx-multiLabel-selector {
        width: 10em;
        /* Same as Label in From */
        position: absolute;
        z-index: 1001;

        a {
            text-decoration: none !important;
            /* Avoid :hover underline */
        }
    }

    .phx-multiLabel-option {
        text-align: right;
        margin-top: 2px;
        margin-bottom: 2px;
        width: 100%;
    }
}

phx-multiLabel {
    display: inline;
}

.phx-multiLabel {
    .phx-multiLabel-blocContainer {
        display: inline;
    }

    .phx-multiLabel-bloc {
        display: inline-block;

        div.phx-selecteur-activite {
            display: inline-block;
        }
    }
}

.phx-multiLabel-bloc td * {
    vertical-align: middle;
}

/** Floating button component
----------------------------------------------*/
div.phx-floating-button.ui-button.ui-state-default {
    margin-left: 2px;
    margin-top: -1px;
}

/*****************************/
/*    links                  */
/*****************************/
.phx-span-link-style:hover {
    cursor: pointer;
    text-decoration: underline;
}

/***********************************************/
/******         WAI-ARIA STYLES         ********/
/***********************************************/
.wai_ariaHiddenDiv {
    width: 0;
    height: 0;
    overflow: hidden;
}

/**-----------------------------------------------------*/
/** ViewsMenu                                         */
/**-----------------------------------------------------*/
.phx-grid-menu .phx-viewsmenu-title {
    text-align: left;
}