/*
YUI 3.7.3 (build 5687)
Copyright 2012 Yahoo! Inc. All rights reserved.
Licensed under the BSD License.
http://yuilibrary.com/license/
*/
/*
	TODO will need to remove settings on HTML since we can't namespace it.
	TODO with the prefix, should I group by selector or property for weight savings?
*/
html {
    color: #000;
    background: white;
}
/*
	TODO remove settings on BODY since we can't namespace it.
*/
/*
	TODO test putting a class on HEAD.
		- Fails on FF.
*/
blockquote,
body,
code,
dd,
div,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
input,
legend,
li,
ol,
p,
pre,
td,
textarea,
th,
ul {
    margin: 0;
    padding: 0;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

fieldset,
img {
    border: 0;
}
/*
	TODO think about hanlding inheritence differently, maybe letting IE6 fail a bit...
*/
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
    font-style: normal;
    font-weight: normal;
}

ol,
ul {
    list-style: none;
}

caption,
th {
    text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: normal;
}

q {
    &:after,
    &:before {
        content: '';
    }
}

abbr,
acronym {
    border: 0;
    font-variant: normal;
}
/* to preserve line-height and selector appearance */
sup {
    vertical-align: text-top;
}

sub {
    vertical-align: text-bottom;
}

input,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
}
/*to enable resizing for IE*/
input,
select,
textarea {
    *font-size: 100%;
}
/* To avoid ie10 to show an X to clear input */
input::-ms-clear {
    width: 0;
    height: 0;
}
/*because legend doesn't inherit in IE */
legend {
    color: #000;
}