/* Reset defaults
----------------------------------*/

html {
    max-height: 100vh;
}

body {
    width: 100%;
    font-size: 12px;
    font-family: Tahoma, Arial, sans-serif;
    max-height: 100vh;
    overflow-y: hidden;
}

body.cw {
    font-family: "Open Sans", Tahoma, Arial, sans-serif;

}

// CW version: Hide datepicker icon while it's initialized
.form-control.hasDatepicker+.ui-datepicker-trigger {
    display: none;
}

/* Home configuration
----------------------------------*/
#phx-wrap {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-align: left;

    // Hide content until theme is loaded
    &:not(.cw-themeLoaded) {
        margin-left: -999999px;
    }
}

#phx-loading_feedback {
    position: fixed;
    top: 20px;
    right: 5px;
}

.l-flex-generalContainer {
    height: 100vh;
}

.phx-header {
    margin: 0;
    padding: 0;
    position: relative;
    min-height: 4.5em;
}

#phx-logo {
    background-repeat: no-repeat;
    cursor: pointer;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 10px;
    left: 25px;
}

#phx-account {
    margin: 0 24px 0 0;
}

#phx-menu-bar {
    background: none;
    border: none;
    display: block;

    .phx-menus-zone {
        display: inline-block;
    }
}

#phx-container {
    width: 100%;
    overflow: hidden;
    display: table;
}

.phx-menu-zone {
    min-width: 480px;
}

.phx-menus-transversal {
    margin-top: 0.4em;
}

.phx-zone {
    display: flex;
    width: 100%;
}

.phx-center {
    width: 100%;
    vertical-align: top;
    overflow: hidden;
    display: table-cell;
}

#phx-copyright {
    padding: 0.75em;
    text-align: left;
    font-style: italic;
}

.phx-simple-panel {
    overflow-y: auto;
}

.phx-reset-borders {
    border: none;
}

.phx-reset-border-bottom {
    border-bottom: none;
}

.phx-reset-border-left {
    border-left: none;
}

.phx-reset-border-top {
    border-top: none;
}

.phx-reset-border-right {
    border-right: none;
}

.phx-icon-hidder {
    position: absolute;
    //margin-top: -2px;
    /* z- index must be lower than pop up box */
    z-index: 99;
    //opacity: $cwHidderOpacity; defined in other-styles/ctime.scss
    //left: -3px;
    width: 100%;
    height: 100%;
    border-radius: 30px;
}

.focusToFormSave {
    position: absolute;
    top: -999px;
    left: -999px;
}

.phx-parent-hidder {
    position: absolute;
    margin-top: 0;
    /* z- index must be lower than pop up box */
    z-index: 101;
    opacity: 0.2;
    left: 0;
    width: 100%;
    height: 100%;
}

.phx-tabs-hidder {
    position: absolute;
    margin-top: -5px;
    /* z- index must be lower than pop up box */
    z-index: 101;
    opacity: 0.2;
    left: 0;
    width: 100%;
    /*sans haut. Elle doit �tre calcul�e*/
}

.phx-panelB1-hidder,
.phx-panelB2-hidder {
    position: absolute;
    margin-top: -9px;
    /* z- index must be lower than pop up box */
    z-index: 101;
    opacity: 0.2;
    left: 0;
    /*Sans hauteur et largeur. Elles doivent �tre calcul�es*/
}

.ui-tooltip-content label {
    display: inline;
}